'use client'
import React from 'react';
import Countdown from 'react-countdown';
import { CountdownRenderer } from './countdown-renderer';


type ReactCountdownProps = {
  date: string | number | Date | undefined;
};

type CountdownProps = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
};

const renderer = ({ days, hours, minutes, seconds, completed }: CountdownProps) => {
    return CountdownRenderer({days, hours, minutes, seconds, completed});
};

const ReactCountdown = ({ date } : ReactCountdownProps) => {    
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (date) {
            setShow(true);
            sessionStorage.removeItem('__jwl_count_started');
        }
    }, [date]);

    return (<>                
        {show && <Countdown date={date} renderer={renderer} onMount={() => {sessionStorage.setItem('__jwl_count_started', new Date().getTime().toString())}}/>}
    </>)
};

export default ReactCountdown;
