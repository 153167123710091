import WidgetLink from './widget-link';
import WidgetSubscription from './widget-subscription';
import { footer } from '../data';
import cn from 'classnames';

interface WidgetsProps {
  lang: string;
  variant?: 'default' | 'medium';
  widgets: {
    id: number;
    widgetTitle: string;
    lists: any;
  }[];
}

const Widgets: React.FC<WidgetsProps> = ({
  lang,
  widgets,
  variant = 'default',
}) => {
  const { social } = footer;
  return (
    <div
      className={`${
        variant === 'default' &&
        'mx-auto max-w-[1920px] px-4 md:px-6 lg:px-8 2xl:px-10'
      }`}
    >
      <div className="flex flex-col-reverse md:grid grid-cols-1 md:grid-cols-1 xl:grid-cols-6 gap-0 sm:gap-9 lg:gap-11 xl:gap-7">
        {widgets?.map((widget) => (
          <WidgetLink
            key={`footer-widget--key${widget.id}`}
            data={widget}
            className="pb-0  -mx-3 md:mx-0 sm:pb-0 col-span-1 md:pb-3.5 md:border-y-0 md:col-span-1 xl:col-span-1 xl:ltr:pl-8"
            lang={lang}
          />
        ))}
        <WidgetSubscription
          className={cn(
            'pt-8  col-span-full sm:col-span-1 md:col-start-3 xl:col-start-4 md:col-span-4 xl:col-span-3 xl:ltr:pl-6 xl:rtl:pr-6 sm:pt-0 sm:border-t-0 border-border-three pb-[50px]',
            {
              '2xl:ltr:pl-7 2xl:rtl:pr-7 3xl:ltr:pl-16 3xl:rtl:pr-16':
                variant === 'default',
            },
          )}
          lang={lang}
          social={social}
        />
      </div>
    </div>
  );
};

export default Widgets;
