'use client'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import Button from './button'
import ShippingCountries from '@public/api/shipping-countries.json'
import { GetCurrencyConfig } from '@services/mage/directory-helper'
import { useCurrency } from '@contexts/currency/use-currency'
import { useCart } from '@contexts/cart/use-cart'
import { GetStoreCurrency, GetStoreId } from '@services/mage/cms-helper'
import { useShippingCountry } from '@contexts/ui/shipping-country-context'
import Cookies from 'js-cookie'


type TCountryProps = {
    code: string;
    name: string;
}

type TProps = {
    lang: string;    
}

type CurrencyProps = {
    id: string;
    storeCode: string;
    name: string;
    value: string;
    icon: JSX.Element;
    exchangeRate: number;
    taxRate: number;
  };
  
  const getCurrentUrl = (lang: string) => {
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname.split('/');
  
    // replace the first element of the array with the language code
    pathname[1] = lang;
  
    return pathname.join('/') + currentUrl.search;
  };


const getCountryCurrency = (countryCode: string) => {

    let vCountryCode = countryCode?.toLowerCase();    

    const currencyCode = GetStoreCurrency(vCountryCode);

    return currencyCode ?? 'USD';
}

const getCountryName = (countryCode: string) => {
    
    let vCountryCode = countryCode?.toUpperCase();

    const countryName = ShippingCountries.find((country) => country.code === vCountryCode)?.name;

    return countryName ?? 'Singapore';
}

export const ShippingCountry = ({lang}: TProps) => {      
    
    const hasRedirectedCountry = Cookies.get('store_redirected');
    const currencyCode = GetStoreCurrency(lang);
    const [countryLang, setCountryLang] = useState(lang);
    const [countryCode, setCountryCode] = useState(hasRedirectedCountry ?? '');
    const [countryName, setCountryName] = useState( lang === 'intl' ? 'Singapore' : lang === 'sg' ? 'Singapore' : 'Malaysia');
    const [countryCurrency, setCountryCurrency] = useState(currencyCode);
    const {setActiveCountryCurrency, setActiveCountryCode, showPopup, setShowPopup} = useShippingCountry();
    const [selectCountry, setSelectCountry] = useState<TCountryProps>({code: countryCode, name: getCountryName(countryCode)});
    const [toBeRedirected, setToBeRedirected] = useState('');
    const [bringMeThere, setBringMeThere] = useState(false);   
    
  
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyProps>(
      GetCurrencyConfig(currencyCode),
    );

    const { setCurrency } = useCurrency();
    const { updateTax, setStoreId } = useCart();
  
    // Currency switcher button
    const switchCurrency = (values: CurrencyProps) => {

      const currentUrl = getCurrentUrl(values.storeCode);
      const storeId = GetStoreId(values.storeCode);
      setStoreId(storeId);
  
      setCurrency({
        rate: selectedCurrency.exchangeRate,
        symbol: selectedCurrency.name,
        taxRate: selectedCurrency.taxRate,
        exchangeRate: selectedCurrency.exchangeRate,
      });
      updateTax(selectedCurrency.taxRate);

      Cookies.set('store_redirected', countryCode, {expires: 1, path: '/', sameSite: 'lax'});

      // session for auto switch currency
      sessionStorage.removeItem('__jwl_auto_switch_currency')

      window.location.href = currentUrl;
  
      return false;
    };


    const setDefaultCountry = (country: string = '') => {

        const voriginCountry = country ?? (document.querySelector('body') as HTMLElement)?.getAttribute('data-country');         

        const vCountryName = getCountryName(voriginCountry?.toUpperCase());
        setSelectCountry({
                code: voriginCountry?.toUpperCase(),
                name: vCountryName
            })

        const countryCurrency = getCountryCurrency(voriginCountry);
        
        setCountryCurrency(countryCurrency);
        setCountryLang(['SG', 'MY'].indexOf(voriginCountry) === -1 ? 'intl' : voriginCountry?.toLowerCase());
        setCountryName(vCountryName);
        setCountryCode(voriginCountry?.toUpperCase());
    }

    useEffect(() => {

        const voriginCountry = (document.querySelector('body') as HTMLElement)?.getAttribute('data-country');         
        const vCountryName = getCountryName(voriginCountry?.toUpperCase());

        if (countryCode === '')
        setCountryCode(voriginCountry?.toUpperCase());

        if (hasRedirectedCountry) {
            
            setActiveCountryCode(hasRedirectedCountry);
            setCountryCode(hasRedirectedCountry);        

        } else {

            let vCountryCode = lang === 'intl' ? 'sg' : lang;

            if (vCountryCode === 'intl') {
                vCountryCode = voriginCountry?.toUpperCase();
            }

            setActiveCountryCode(vCountryCode?.toUpperCase());
            setCountryCode(vCountryCode?.toUpperCase());
        }        
      
        if (!sessionStorage.getItem('store_redirected_stay')) {            

            let showMePopup = false;
          
            //check the country lang
            const storeLang = ['SG', 'MY'].indexOf(voriginCountry?.toUpperCase()) === -1 ? 'intl' : voriginCountry.toLowerCase();

            if (countryCode !== '' && voriginCountry?.toUpperCase() !== countryCode?.toUpperCase()) {
                showMePopup = true;
            }

            if (storeLang !== lang) {
                showMePopup = true;
            }

            /**
             * not the same country
             * and not the same language
             * not empty country code
             */
            if (showMePopup) {

                const vOriginCountry = voriginCountry?.toUpperCase();

                setDefaultCountry(vOriginCountry);
                setBringMeThere(true);
                
                setShowPopup(true);               

                if (['SG', 'MY'].indexOf(vOriginCountry) === -1) {
                    setToBeRedirected('international');
                } else {
                    setToBeRedirected(vCountryName);
                }


            } else {
                
                const vCountryCode = countryCode === '' ? voriginCountry?.toUpperCase() : countryCode?.toUpperCase();
                setBringMeThere(false);
                setToBeRedirected('');
                
                setSelectCountry({
                        code: vCountryCode,
                        name: getCountryName(vCountryCode)
                    })

            }
        }
        
      
    }, []);

    useEffect(() => {
        
        //if switching currency via url
        if (!sessionStorage.getItem('store_selected_country')) {
            // auto switch currency
            const autoSwitchCurrencyTag = sessionStorage.getItem('__jwl_auto_switch_currency');

            if (autoSwitchCurrencyTag !== lang) {

                const ccValue = getCountryCurrency(lang);
                const currentCurrency = GetCurrencyConfig(ccValue);
                
                setCurrency({
                    rate: currentCurrency.exchangeRate,
                    symbol: currentCurrency.name,
                    taxRate: currentCurrency.taxRate,
                    exchangeRate: currentCurrency.exchangeRate,
                });

                setActiveCountryCode(lang);
                updateTax(currentCurrency.taxRate);                

            }

            sessionStorage.setItem('__jwl_auto_switch_currency', lang);

        }

    }, [lang]);

  return (   
  
  <>
      <Dialog open={showPopup} onClose={() => {

            setShowPopup(false)        

            sessionStorage.removeItem('store_redirected');
            sessionStorage.removeItem('store_selected_country');
        
        }} className="relative z-40" id='shipping-country-dialog' area-label="Shipping Country Dialog">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"        
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="relative">
          <DialogPanel
            transition
            className="absolute top-20 right-10 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          > 

          <div className='px-10 py-6'>

            <div className='text-2xl font-semibold text-center'>I'm shipping to</div>
                <div className='mt-3'>
                    <select
                    id='shipping-country'
                    aria-label='Shipping Country'
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"                    
                    value={JSON.stringify(selectCountry)}                    
                    onChange={(e) => {
                        const country = JSON.parse(e.target.value);

                        const ccValue = getCountryCurrency(country.code);                            

                        setActiveCountryCode(country.code);

                        setCountryCode(country.code)
                        setCountryName(country.name)
                        setCountryCurrency(ccValue)

                        const currentCurrency = GetCurrencyConfig(ccValue);
                        setSelectedCurrency(currentCurrency);

                        setCountryLang(['SG', 'MY'].indexOf(country.code) === -1 ? 'intl' : country.code?.toLowerCase());
                        setToBeRedirected(['SG', 'MY'].indexOf(country.code) === -1 ? 'international' : country.name);

                        setActiveCountryCurrency(currentCurrency);                            
                        setSelectCountry(country);

                        sessionStorage.setItem('store_selected_country', country.code);

                        setBringMeThere(true);


                    }}
                >
                    {ShippingCountries.map((country, index) => (
                        <option key={index} value={JSON.stringify(country)}                                                
                        >{country?.name}</option>
                    ))}                        
                </select>
                </div>
                {toBeRedirected && <div className='mt-4 text-center'>You will be redirected to the {toBeRedirected} site.</div>}
                <div className='mt-4 text-center text-red-600'>Please note your final order will be billed in {countryCurrency}</div>
                <div className='mt-4 text-center'>
                    <Button
                        onClick={() => {                                                        


                            if (bringMeThere) {

                                sessionStorage.setItem('store_redirected', countryCode);

                                if (sessionStorage.getItem('store_selected_country')) {

                                    sessionStorage.removeItem('store_selected_country')
                                    switchCurrency(selectedCurrency);

                                } else {

                                    const ccValue = getCountryCurrency(countryCode);
                                    const currentCurrency = GetCurrencyConfig(ccValue);

                                    switchCurrency(currentCurrency);

                                    sessionStorage.removeItem('store_selected_country')

                                }

                                setShowPopup(false)                               


                            } else {

                                setShowPopup(false)
                            } 
                            
                        
                        }}
                        className="!bg-[#0a1c56] text-white"
                >
                    {bringMeThere ? `Bring me there` : `Continue Shopping`}
                    </Button></div>

                <div className='mt-2 text-center text-sm underline cursor-pointer'
                    onClick={() => 
                    {
                        setShowPopup(false)
                        // set a cookie to remember the user's choice
                        sessionStorage.setItem('store_redirected_stay', countryCode);
                    }
                    }
                >Stay on this website</div>

</div>           
            
          </DialogPanel>
        </div>
      </div>
    </Dialog>

    </>
  )
}
