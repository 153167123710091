import CartButton from '@components/cart/cart-button';
import WishlistButton from '@components/cart/wishlist-button';
import React from 'react'


const CartButtons = ({ lang }: { lang: string }) => {
  return (
    <>
      <WishlistButton className="xl:mx-2.5 mx-2" lang={lang} />
      <CartButton className="xl:mx-2.5 mx-2" lang={lang} hideLabel={true} />
    </>
  );
};

export default CartButtons;
