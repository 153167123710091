import { useUI } from '@contexts/ui.context';
import { useTranslation } from '@app/i18n/client';
import cn from 'classnames';
import { useSession } from 'next-auth/react';
import { TCustomer } from '@services/mage/customer-types';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { capitalize } from 'lodash';

type TButtonProps = {
  lang: string;
  className?: string;
  customer?: TCustomer;
};

const LogInfoButton = ({ lang, className, customer }: TButtonProps) => {
  const { t } = useTranslation('en', 'common');
  const { openDrawer, setDrawerView } = useUI();

  function handleLogInfoOpen() {
    setDrawerView('LOGINFO_SIDEBAR');
    // isShowing;
    return openDrawer();
  }

  return (
    <button
      className={cn(
        'flex items-center justify-center shrink-0 h-auto focus:outline-none transform',
        className,
      )}
      onClick={handleLogInfoOpen}
      aria-label="wishlist-button"
      data-id={customer.entity_id}
    >
      <div className="relative flex items-center">
        <span className="hidden lg:flex lg:items-center">
          <span
            aria-hidden="true"
            className="ml-2 text-xs font-semibold leading-6 text-gray-500"
          >
            {capitalize(customer?.firstname)}
          </span>
          <ChevronDownIcon
            aria-hidden="true"
            className="ml-1 h-4 w-4 text-gray-600 "
          />
        </span>
      </div>
    </button>
  );
};

export default LogInfoButton;
