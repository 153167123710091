'use client';
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild
} from '@headlessui/react';
import { CalendarDaysIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { navigation } from '@settings/menu-setting';
import { useState } from 'react';
import { HiMagnifyingGlassCircle } from 'react-icons/hi2';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { useModalStore } from '@components/common/modal/modal.context';
import CurrencySwitcher from '@components/ui/currency-switcher';
import { useMenuStore } from '@contexts/ui/menu-context';
import { useLogoutMutation } from '@framework/auth/use-logout';
import { setUrl } from '@utils/url';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import React, { useCallback } from 'react';

const HeaderMenuMobile = ({ lang }: { lang: string }) => {
  const { data: session } = useSession();
  const { showMobileMenu, closeMenu } = useMenuStore();  
  const { mutate: logout } = useLogoutMutation(lang);
  

  const onClick = useCallback(() => {    
    closeMenu();
  }, []);

  const [searchValue, setSearchValue] = useState('');
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const { openModal } = useModalStore();
  
  return (
    <>
      <Dialog
        open={showMobileMenu}
        onClose={closeMenu}
        className="relative z-40"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[leave]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 flex max-w-full pr-10">
              <DialogPanel
                transition
                className="pointer-events-auto relative w-screen max-w-md transform transition duration-500 ease-in-out data-[enter]:-translate-x-full data-[leave]:-translate-x-full sm:duration-500"
              >
                <TransitionChild>
                  <div className="absolute right-0 top-0 -mr-8 flex pt-4 duration-500 ease-in-out data-[leave]:opacity-0">
                    <button
                      type="button"
                      onClick={() => closeMenu()}
                      className="relative rounded-md text-gray-50 hover:text-white"
                    >
                      <span className="absolute -inset-2.5" />
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                </TransitionChild>

                <div className="flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl">
                  <div className="relative flex-none px-4">
                    <TabGroup>
                      <TabList className="flex gap-4 items-center justify-center">
                        <Tab className="uppercase mb-3 text-sm font-semibold border-b data-[selected]:border-blue-500 data-[hover]:border-blue-900">
                          Menu
                        </Tab>
                        <Tab className="uppercase mb-3 text-sm font-semibold border-b data-[selected]:border-blue-500 data-[hover]:border-blue-900">
                          Account
                        </Tab>
                        <Tab className="uppercase mb-3 text-sm font-semibold border-b data-[selected]:border-blue-500 data-[hover]:border-blue-900">
                          Currency
                        </Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          <div className="flex items-center mt-1.5 mb-2.5">
                            <div className="relative w-full">
                              <input
                                name="search"
                                type="text"
                                placeholder="Type a product name or SKU"
                                className="block w-full rounded-full shadow-md border-0 py-3 pr-2 md:pr-10 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset text-base focus:ring-brand-blue-2"
                                autoComplete="off"
                                value={searchValue}
                                onChange={handleSearchInput}
                              />
                              <div
                                className=" absolute inset-y-0 right-0 flex items-center pr-1"
                                onClick={() => {
                                  openModal('SEARCH_VIEW', searchValue);
                                  closeMenu();
                                }}
                              >
                                <HiMagnifyingGlassCircle
                                  aria-hidden="true"
                                  className="h-8 w-8 text-gray-300"
                                />
                              </div>
                            </div>
                          </div>
                          {navigation.categories.map((category) => (
                            <Disclosure
                              as="div"
                              key={category.id}
                              className="pb-3 border-b border-gray-200 mb-3 mt-5"
                            >
                              <DisclosureButton className="group text-left flex items-center justify-between w-full">
                                <span
                                  className="text-sm uppercase font-medium text-gray-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = setUrl(category.id, lang);
                                  }}
                                >
                                  {category.name}
                                </span>
                                <ChevronDownIcon className="size-5  group-data-[open]:rotate-180" />
                              </DisclosureButton>
                              <DisclosurePanel className="mt-2 text-sm/">
                                {<category.component lang={lang} />}
                              </DisclosurePanel>
                            </Disclosure>
                          ))}

                          {/* Pages and Social Media */}
                          <div className=" border-gray-200 py-2">
                            <Link
                              onClick={onClick}
                              href={setUrl('make-an-appointment', lang)}
                              className="bg-brand-blue-0 text-white text-15px py-2.5 rounded-sm flex items-center mx-auto mt-5 justify-center w-full"
                            >
                              <CalendarDaysIcon className="h-5 w-5 mr-2" />
                              Book An Appointment
                            </Link>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          {/* Pages and Social Media */}
                          <div className=" border-gray-200 py-2">
                            {session !== null ? (
                              <>
                                {navigation.pages.map((page) => (
                                  <div key={page.name} className="flow-root">
                                    <a
                                      href={setUrl(page.href, lang)}
                                      className="block py-2 text-normal font-medium text-gray-500 uppercase"
                                    >
                                      {page.name}
                                    </a>
                                  </div>
                                ))}
                                <div className="flow-root">
                                  <a
                                    href="#"
                                    onClick={() => logout()}
                                    className="block py-2 text-normal font-medium text-gray-500 uppercase"
                                  >
                                    Sign Out
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flow-root">
                                  <a
                                    href={setUrl('signin', lang)}
                                    className="block py-2 text-normal font-medium text-gray-500 uppercase"
                                  >
                                    Sign In
                                  </a>
                                </div>
                                <div className="flow-root">
                                  <a
                                    href={setUrl('signup', lang)}
                                    className="block py-2 text-normal font-medium text-gray-500 uppercase"
                                  >
                                    Create an Account
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="py-2 flex justify-center">
                            <div className="text-sm">Choose a currency :</div>
                            <CurrencySwitcher lang={lang} />
                          </div>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default HeaderMenuMobile;
