'use client';

/* import React from 'react'; */

import { create } from 'zustand';

type MODAL_VIEWS =
  | 'SIGN_UP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGET_PASSWORD'
  | 'PAYMENT'
  | 'ADDRESS_VIEW_AND_EDIT'
  | 'NEW_ADDRESS'
  | 'SHOP_VIEW'
  | 'PHONE_NUMBER'
  | 'DELIVERY_VIEW'
  | 'PRODUCT_VIEW'
  | 'CATEGORY_VIEW'
  | 'SEARCH_VIEW'

interface IModalState {
  view?: MODAL_VIEWS;
  data?: any;
  isOpen: boolean;
  openModal: (view?: MODAL_VIEWS, payload?: any) => void;
  closeModal: () => void;
  closeModalWithData: (view?: MODAL_VIEWS, payload?: any) => void;
}

const initialModalState: IModalState = {
  view: undefined,
  isOpen: false,
  data: null,
  openModal: () => {},
  closeModal: () => {},
  closeModalWithData: () => {},
};

export const useModalStore = create<IModalState>((set) => ({
  ...initialModalState,
  openModal: (view?: MODAL_VIEWS, payload?: any) =>
    set(() => ({ view, data: payload, isOpen: true })),
  closeModal: () => set(() => ({ isOpen: false })),
  closeModalWithData: (view?: MODAL_VIEWS, payload?: any) =>
    set(() => ({ view, data: payload, isOpen: false })),
}));
