import { useStore } from '@contexts/store/store';
import { useShallow } from 'zustand/react/shallow';

export function useCurrency() {
  return useStore(
    useShallow((store) => ({
      currency: store.currency,
      setCurrency: store.setCurrency,
      formatPrice: store.formatPrice,
      formatPriceFromSymbol: store.formatPriceFromSymbol,
      formatPriceFromCurrency: store.formatPriceFromCurrency,
      formatPlainPrice: store.formatPlainPrice,
    })),
  );
}
