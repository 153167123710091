'use client';

import { create } from 'zustand';

interface IFilterState {
  showDesktopMenu: boolean;
  showMobileMenu: boolean;
  activeMenu: string;
  menu: string;
  closeMenu: () => void;
  openMenu: (menu: string) => void;
  setActiveMenu: (menu: string) => void;
}

export const useMenuStore = create<IFilterState>((set) => ({
  showDesktopMenu: false,
  showMobileMenu: false,
  menu: null,
  activeMenu: null,
  closeMenu: () => {
    const isMobile = window.innerWidth < 1024;
    if (isMobile) {
      set(() => ({ showMobileMenu: false, menu: null }));
    } else {
      set(() => ({ showDesktopMenu: false, menu: null }));
    }
  },
  openMenu: (menu: string) => {
    const isMobile = window.innerWidth < 1024;
    if (isMobile) {
      set(() => ({ showMobileMenu: true, menu }));
    } else {
      set(() => ({ showDesktopMenu: true, menu }));
    }
  },
  setActiveMenu: (menu) => set(() => ({ activeMenu: menu })),
}));
