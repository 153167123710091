'use client'
import { useShippingCountry } from '@contexts/ui/shipping-country-context';
import {
    Menu,
    MenuButton
} from '@headlessui/react';
import {
    ChevronDownIcon,
} from '@heroicons/react/24/outline';
import {
    GetStoreCurrency,
} from '@services/mage/cms-helper';
import { GetCurrencyConfig } from '@services/mage/directory-helper';
import { TCurrencyProps } from '@services/mage/directory-types';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { isEmpty } from 'lodash';


export default function CurrencySwitcher({ lang }: { lang: string }) {   
  
  const flagPath = 'https://michaeltrio.s3.ap-southeast-1.amazonaws.com/assets/icons/flags/';
  const currencyCode = GetStoreCurrency(lang);
  const [countryCode, setCountryCode] = useState('');  
  const [, setCountryOrigin] = useState('');
  const {selectedCountryCurrency, selectedCountryCode, setShowPopup} = useShippingCountry();
  const [selectedCurrency, setSelectedCurrency] = useState<TCurrencyProps>({} as TCurrencyProps);  
  const [flag, setFlag] = useState(`${flagPath}sg.svg`);

  useEffect(() => {        
    
    const originCountry = (document.querySelector('body') as HTMLElement).getAttribute('data-country'); 
    setCountryOrigin(originCountry);        

    if (Object.hasOwn(selectedCountryCurrency, 'id')) {
        setSelectedCurrency(selectedCountryCurrency);     
    } else {
        setSelectedCurrency(GetCurrencyConfig(currencyCode));
    }

    if (isEmpty(countryCode))
    setCountryCode(originCountry);
    else
    setCountryCode(selectedCountryCode);

    if (isEmpty(countryCode))
    setFlag(`${flagPath}sg.svg`);

  }, [selectedCountryCurrency, selectedCountryCode]);

  return (
    <>
        {countryCode && selectedCurrency && <Menu>
            <MenuButton className="z-30 inline-flex items-center gap-2 rounded-sm bg-white py-1.5 px-3 text-sm"
                onClick={() => setShowPopup(true)}
            >
                <div className="w-[22px]">{flag && <Image width="22" height="22" alt={selectedCurrency?.name ?? 'Singapore'} src={flag} />}</div>
                <div>{selectedCurrency?.name}</div>
                <ChevronDownIcon className="size-4 fill-white/60" />
            </MenuButton>
        </Menu>}
    </>
  );
}
