import Link from 'next/link';
import {
  HiOutlineCalendar,
  HiOutlineBuildingStorefront,
} from 'react-icons/hi2';
import { setUrl } from '@utils/url';

export default function HeaderLocation({ lang }: { lang: string }) {
  return (
    <>
      <Link
        className="group inline-flex mr-6 items-center text-13px"
        href={setUrl('locations', lang)}
      >
        <span className="md:mr-2">
          <HiOutlineBuildingStorefront className="h-5 w-5 text-gray-400" />
        </span>
        <span className="hidden xl:inline-block uppercase">Stores</span>
      </Link>
      <Link
        className="group inline-flex items-center text-13px"
        href={setUrl('make-an-appointment', lang)}
      >
        <span className="md:mr-2">
          <HiOutlineCalendar className="h-5 w-5 text-gray-400" />
        </span>
        <span className="hidden xl:inline-block text-rhino-700 uppercase">
          Appointment
        </span>
      </Link>
    </>
  );
}
