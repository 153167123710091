'use client'
import { useModalStore } from '@components/common/modal/modal.context';
import Logo from '@components/ui/logo';
import { useUI } from '@contexts/ui.context';
import { useMenuStore } from '@contexts/ui/menu-context';
import CartButtons from '@layouts/header/cart-buttons';
import HeaderLocation from '@layouts/header/header-location';
import HeaderMenu from '@layouts/header/header-menu';
import HeaderTools from '@layouts/header/header-tools';
import { useActiveScroll } from '@utils/use-active-scroll';
import clsx from 'clsx';
import React, { useRef } from 'react';

const Header = ({ lang }: { lang: string }) => {
  
  const { displayMobileSearch } = useUI();
  const siteHeaderRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useActiveScroll(siteHeaderRef);
  const { closeMenu } = useMenuStore();  
  const { openModal } = useModalStore();

  const onLogin = () => {
    openModal('LOGIN_VIEW');
    }

  return (
    <>
      <div
        className="z-30 w-full transition duration-200 ease-in-out body-font bg-brand-light lg:pt-4"
        onMouseLeave={() => {
          closeMenu();
        }}
      >
        {/* End of Mobile search */}
        <div className="flex items-center w-full h-full px-4 md:px-6 lg:px-8 2xl:px-10">
          <div className="flex-1 hidden lg:flex">
            <HeaderLocation lang={lang} />
          </div>
          <div className="items-center hidden lg:flex">            
            <Logo lang={lang} className="w-60" />
          </div>
          <div className="flex flex-1 items-center justify-end z-30">
          <HeaderTools lang={lang} onSearchOpen={() => openModal('SEARCH_VIEW')} onLogin={onLogin}>
              <CartButtons lang={lang} />
            </HeaderTools>
          </div>
        </div>
        {/* End of Mobile search */}
      </div>

      <div
        id="siteHeader"
        ref={siteHeaderRef}
        className={clsx(
          'header-one w-full z-20 sticky top-0 mt-2',
          displayMobileSearch && 'active-mobile-search',
        )}
      >
        <div className="z-20 w-full transition duration-200 ease-in-out innerSticky body-font bg-brand-light border-b border-gray-100 lg:pt-2">
          <div className="flex lg:px-10 justify-center w-full">
            <div className="flex-1 items-center w-full">
              <div className="flex-1 hidden lg:flex header-location z-40">
                <HeaderLocation lang={lang} />
              </div>
            </div>
            <div className="flex w-full">
              <HeaderMenu lang={lang}>
                <CartButtons lang={lang} />
              </HeaderMenu>
            </div>
            <div className="flex-1 items-center w-full">
              <div className="flex flex-1 items-center justify-end header-tools z-30">
                <HeaderTools lang={lang} onSearchOpen={() => openModal('SEARCH_VIEW')} onLogin={onLogin}>
                  <CartButtons lang={lang} />
                </HeaderTools>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
