'use client';
import { Fragment, ReactNode, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { navigation } from '@settings/menu-setting';
import Logo from '@components/ui/logo';
import { useRouter } from 'next/navigation';
import { useMenuStore } from '@contexts/ui/menu-context';
import { motion, AnimatePresence } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import React from 'react';

// children: ReactNode;

const PanelContent = ({ children }: { children: ReactNode }) => {
  const { closeMenu } = useMenuStore();
  return (
    <div className="relative bg-white shadow-md">
      <div
        className="mx-auto"
        onMouseLeave={(e) => {
          closeMenu();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default function HeaderMenuDesktop({
  lang,
  children,
}: {
  lang: string;
  children: ReactNode;
}) {
  const router = useRouter();
  const {
    menu,
    openMenu,
    closeMenu,
    activeMenu,
    setActiveMenu,
    showMobileMenu,
  } = useMenuStore();
  const [selected, setSelected] = useState(menu);

  const onHover = (menuId: string) => {
    setSelected(menuId);
    openMenu(menuId);
  };

  const onLeave = () => {
    setSelected(null);
    closeMenu();
  };

  return (
    <>
      {/* Desktop menu */}
      <div className="main-menu bg-white text-sm w-full">
        <nav aria-label="Top" className="lg:mx-auto lg:w-fit">
          <div className="flex items-center justify-between">
            <div className="flex items-center w-full xs:pb-2 sm:pb-2 lg:hidden lg:py-4">
              <div className="flex-none items-center justify-end">
                <button
                  type="button"
                  className="rounded-md bg-white p-2 text-brand-blue-0"
                  onClick={() => (showMobileMenu ? closeMenu() : openMenu(''))}
                >
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>

              <div className="flex justify-center flex-grow items-center">
                <Logo lang={lang} className="ml-2 py-2" />
              </div>

              <div className="flex items-center justify-end mr-2">
                {children}
              </div>
            </div>

            {/* Flyout menus */}
            <div className="hidden lg:block absolute bottom-0 left-0 w-full menu-block">
              <div
                className="relative"
                onMouseLeave={(e) => {
                  e.preventDefault();
                  onLeave();
                }}
              >
                <div className="flex items-center justify-center">
                  {navigation.categories.map((category) => (
                    <Fragment key={`nav-${category.id}`}>
                      <motion.button
                        className={clsx(
                          `text-13px pb-4 px-2 uppercase 
                    tracking-wider border-b-2 border-b-transparent 
                    hover:border-b-2 hover:border-gray-500`,
                          activeMenu === category.id &&
                            'border-b-2 border-gray-500',
                          'border-b-2 border-gray-500',
                        )}
                        onMouseEnter={(e) => {
                          e.preventDefault();
                          onHover(category.id);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          // no need to push if mobile
                          if (!isMobile) {
                            setActiveMenu(category.id);
                            closeMenu();
                            location.href = `/${lang}/${category.id}`;
                          }
                        }}
                      >
                        {category.name}
                      </motion.button>

                      {menu === category.id && (
                        <AnimatePresence>
                          <motion.div
                            initial={{
                              opacity: 0,
                            }}
                            transition={{ duration: 0.4, ease: 'easeInOut' }}
                            animate={{
                              opacity: 1,
                            }}
                            exit={{
                              opacity: 0,
                            }}
                            className="absolute left-0 inset-x-0 top-full w-full z-50"
                          >
                            <PanelContent>
                              <category.component lang={lang} />                              
                            </PanelContent>
                          </motion.div>
                        </AnimatePresence>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
