import { useUI } from '@contexts/ui.context';
import { useTranslation } from '@app/i18n/client';
import cn from 'classnames';
import { useWishlist } from '@contexts/cart/use-wishlist';
import { IoMdHeartEmpty } from 'react-icons/io';

type WishlistButtonProps = {
  lang: string;
  className?: string;
  iconClassName?: string;
};

const WishlistButton: React.FC<WishlistButtonProps> = ({
  lang,
  className,
  iconClassName = 'text-brand-dark text-opacity-40',
}) => {
  const { t } = useTranslation('en', 'common');
  const { openDrawer, setDrawerView } = useUI();
  const { wishlist } = useWishlist();

  function handleWishlistOpen() {
    setDrawerView('WISHLIST_SIDEBAR');
    // isShowing;
    return openDrawer();
  }

  return (
    <button
      className={cn(
        'flex items-center justify-center shrink-0 h-auto focus:outline-none transform',
        className,
      )}
      onClick={handleWishlistOpen}
      aria-label="wishlist-button"
    >
      <div className="relative flex items-center">
        <IoMdHeartEmpty className="h-5 w-5 text-gray-400" />
        {wishlist.totalItems > 0 && (
          <span className="w-[15px] h-[15px] p-0.5 rounded-[20px] flex items-center justify-center bg-brand text-brand-light absolute -top-2 ltr:left-3.5 rtl:right-3.5 text-[8px] md:text-10px font-semibold">
            {wishlist.totalItems}
          </span>
        )}
      </div>
    </button>
  );
};

export default WishlistButton;
