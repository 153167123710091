export const footer = {
  widgets: [
    {
      id: 1,
      widgetTitle: 'widget-title-about',
      lists: [
        {
          id: 1,
          title: 'link-our-story',
          path: '/about-us',
        },
        {
          id: 2,
          title: 'link-showrooms',
          path: '/locations',
        },
        {
          id: 3,
          title: 'link-contact-us',
          path: '/contacts',
        },
        {
          id: 4,
          title: 'link-customer-reviews',
          path: '/reviews',
        },
        {
          id: 5,
          title: 'link-terms-and-conditions',
          path: '/terms-condition',
        },
        {
          id: 6,
          title: 'link-privacy',
          path: '/privacy-policy',
        },
        {
          id: 7,
          title: 'link-conflict-free-diamonds',
          path: '/faq#conflict-free-diamonds',
        },
        {
          id: 8,
          title: 'link-faqs',
          path: '/faq',
        },
        {
          id: 10,
          title: 'link-blog',
          path: 'https://blog.michaeltrio.com',
        },
        {
          id: 9,
          title: 'link-sitemap',
          path: '/user-sitemap',
        },
      ],
    },
    {
      id: 2,
      widgetTitle: 'widget-title-customer-service',
      lists: [
        {
          id: 1,
          title: 'link-free-shipping',
          path: '/faq#shipping',
        },
        {
          id: 2,
          title: 'link-custom-made',
          path: '/faq#custom-made-orders',
        },
        {
          id: 3,
          title: 'link-14-day-returns',
          path: '/faq#14-day-return-policy',
        },
        {
          id: 4,
          title: 'link-ring-size-resizing',
          path: '/faq#orders-and-ring-sizes',
        },
        {
          id: 5,
          title: 'link-diamond-upgrade-program',
          path: '/faq#diamond-ring-upgrade-policy',
        },
        {
          id: 6,
          title: 'link-diamond-price-matching',
          path: '/faq#diamond-price-match-programme',
        },
        {
          id: 7,
          title: 'link-lifetime-warranty',
          path: '/faq#lifetime-warranty',
        },
        {
          id: 8,
          title: 'link-free-ring-sizer',
          path: '/request-ring-sizer',
        },
      ],
    },
  ],
  payment: [
    {
      id: 1,
      path: '/',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/visa.svg',
      name: 'payment-visa',
      width: 50,
      height: 20,
    },
    {
      id: 2,
      path: '/',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/mastercard.svg',
      name: 'payment-master-card',
      width: 34,
      height: 20,
    },
    {
      id: 3,
      path: '/',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/amex.svg',
      name: 'payment-amex',
      width: 65,
      height: 20,
    },
    {
      id: 4,
      path: '/',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/grab.svg',
      name: 'payment-grab',
      width: 65,
      height: 20,
    },
    {
      id: 5,
      path: '/',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/atome.svg',
      name: 'payment-atome',
      width: 55,
      height: 20,
    },
  ],
  social: [
    {
      id: 1,
      path: 'https://www.facebook.com/michaeltriojewels',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/facebook.svg',
      name: 'facebook',
      width: 20,
      height: 20,
    },
    {
      id: 2,
      path: 'https://www.instagram.com/michaeltrio',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/instagram.svg',
      name: 'instagram',
      width: 20,
      height: 20,
    },
    {
      id: 3,
      path: 'https://www.youtube.com/channel/UCDA5wCS594VZcW4gqTx2GDw',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/youtube.svg',
      name: 'youtube',
      width: 20,
      height: 20,
    },
    {
      id: 4,
      path: 'https://www.tiktok.com/@michaeltrio',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/tiktok.svg',
      name: 'tiktok',
      width: 20,
      height: 20,
    },
    {
      id: 5,
      path: 'https://www.xiaohongshu.com/user/profile/62d62310000000000303d420',
      image: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/icons/xiaohongshu.svg',
      name: 'xiaohongshu',
      width: 20,
      height: 20,
    },
  ],
};
