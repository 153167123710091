import Heading from '@components/ui/heading';
import Link from '@components/ui/link';
import { useTranslation } from 'src/app/i18n/client';
import React, { useState } from 'react';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { setUrl } from '@utils/url';

interface Props {
  className?: string;
  lang: string;
  data: {
    widgetTitle?: string;
    lists: {
      id: string;
      path?: string;
      title: string;
      icon?: any;
    }[];
  };
}

const WidgetLink: React.FC<Props> = ({ lang, className, data }) => {
  const { widgetTitle, lists } = data;
  const { t } = useTranslation('en', 'footer');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={`${className}`}>
      <Heading
        variant="mediumHeading"
        className="hidden sm:flex mb-4 sm:mb-5 lg:mb-6 pb-0.5 uppercase"
      >
        {t(`${widgetTitle}`)}
      </Heading>

      <button
        className="sm:!hidden inline-flex justify-between items-center w-full text-start text-black border-t-2"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Heading
          variant="mediumHeading"
          className="my-4 mx-4 sm:mb-5 lg:mb-6 uppercase font-semibold"
        >
          {t(`${widgetTitle}`)}
        </Heading>
        {isDropdownOpen ? (
          <BiSolidUpArrow className="w-[18px] 2xl:w-5 h-[18px] 2xl:h-5 mx-4" />
        ) : (
          <BiSolidDownArrow className="w-[18px] 2xl:w-5 h-[18px] 2xl:h-5 mx-4" />
        )}
      </button>

      <ul
        className={`${
          isDropdownOpen ? 'block' : 'hidden'
        }  space-y-3 text-sm lg:text-15px px-4 py-4 `}
      >
        {lists.map((list) => (
          <li
            key={`widget-list--key${list.id}`}
            className="flex items-baseline"
          >
            {list.icon && (
              <span className="ltr:mr-3 rtl:ml-3 relative top-0.5 lg:top-1 text-sm lg:text-base">
                {list.icon}
              </span>
            )}

            <Link
              href={setUrl(list.path, lang)}
              className="transition-colors duration-200 hover:text-brand-dark"
            >
              {t(`${list.title}`)}
            </Link>
          </li>
        ))}
      </ul>
      {/* Regular horizontal menu for larger screens */}
      <ul className="hidden sm:flex flex-col space-y-3 text-sm lg:text-15px">
        {lists.map((list) => (
          <li
            key={`widget-list--key${list.id}`}
            className="flex items-baseline"
          >
            {list.icon && (
              <span className="ltr:mr-3 rtl:ml-3 relative top-0.5 lg:top-1 text-sm lg:text-base">
                {list.icon}
              </span>
            )}

            <Link
              href={setUrl(list.path, lang)}
              className="transition-colors duration-200 hover:text-brand-dark"
            >
              {t(`${list.title}`)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WidgetLink;
