'use client';

import { TCurrencyProps } from '@services/mage/directory-types';
import { create } from 'zustand';

interface IFilterState {
    showPopup: boolean;    
    selectedCountryCode: string;
    selectedCountryCurrency: TCurrencyProps;
    setActiveCountryCurrency: (currency: TCurrencyProps) => void;    
    setActiveCountryCode: (countryCode: string) => void;    
    setShowPopup: (showPopup: boolean) => void;
}

export const useShippingCountry = create<IFilterState>((set) => ({
    selectedCountryCurrency: {} as TCurrencyProps,
    selectedCountryCode: 'SG',
    showPopup: false,
    setActiveCountryCurrency: (currency: TCurrencyProps) => set(() => ({ selectedCountryCurrency: currency })),     
    setActiveCountryCode: (countryCode: string) => set(() => ({ selectedCountryCode: countryCode })),    
    setShowPopup: (showPopup: boolean) => set(() => ({ showPopup })),
}));
