import { useUI } from '@contexts/ui.context';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { useMutation } from '@tanstack/react-query';
import { signOut } from 'next-auth/react';

export interface LoginInputType {
  email: string;
  password: string;
  remember_me: boolean;
}
async function logout() {
    
  // Nextauth signin credentials
  await signOut();

  return {
    ok: true,
  };
}
export const useLogoutMutation = (lang: string) => {
  const { unauthorize } = useUI();
  const router = useRouter();

  return useMutation({
    mutationFn: () => logout(),
    onSuccess: (_data) => {

      Cookies.remove('customer_token', { path: '' });
      Cookies.remove('customer_session', { path: '' });
      Cookies.remove('checkout_order', { path: '' });

      sessionStorage.clear();
      localStorage.clear();      

      unauthorize();

      router.push(`/`);
      
    },
    onError: (data) => {
      console.log(data, 'logout error response');
    },
  });
};
