'use client';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '@components/ui/form/input';
import { useTranslation } from 'src/app/i18n/client';
import Text from '@components/ui/text';
import Heading from '@components/ui/heading';
import cn from 'classnames';
import Image from '@components/ui/image';
import Link from 'next/link';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSubscribeMutation } from '@framework/customer/use-subscribe';

interface NewsLetterFormValues {
  email: string;
}
const defaultValues = {
  email: '',
};

function SubscriptionForm({ lang }: { lang: string }) {
  const { t } = useTranslation('en', 'forms');
  const { mutate: changeSubscribe } = useSubscribeMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewsLetterFormValues>({
    defaultValues,
  });

  const [subscriptionSuccess, setSubscriptionSuccess] =
    useState<boolean>(false);

  function onSubmit(values: NewsLetterFormValues) {
    changeSubscribe(values.email);

    // show success message
    setSubscriptionSuccess(true);

    // remove success message after 3 seconds
    setTimeout(() => {
      setSubscriptionSuccess(false);
    }, 5000);

    reset();

    // reset form after submit
    //e.target.reset();
  }
  return (
    <form
      noValidate
      className="relative mt-5 max-w-[700px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        placeholder={t('placeholder-email-subscribe')}
        type="email"
        id="subscription-email"
        variant="solid"
        className="w-full"
        inputClassName="h-12 border-t-0 border-x-0 border-b-[#585858] bg-transparent py-1.5 text-gray-900 sm:text-sm sm:leading-6 rounded-none "
        {...register('email', {
          required: `${t('email-required')}`,
          pattern: {
            value:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: `${t('email-error')}`,
          },
        })}
        error={errors.email?.message}
        lang={lang}
      />
      {!errors.email && subscriptionSuccess && (
        <p className="my-2 text-13px text-brand">
          {t('text-subscription-success-msg')}
        </p>
      )}
      <button
        className="inline-flex items-center gap-x-1.5 absolute ltr:right-0 rtl:left-0 top-0 hover:opacity-80 focus:outline-none h-12 px-3 lg:px-3.5 transform scale-90 2xl:scale-100"
        aria-label="Subscribe Button"
      >
        Subscribe{' '}
        <BiSolidRightArrow className="w-[18px] 2xl:w-5 h-[18px] 2xl:h-5" />
      </button>
    </form>
  );
}

interface Props {
  lang: string;
  className?: string;
  social?: {
    id: string | number;
    path?: string;
    name: string;
    image: string;
    width: number;
    height: number;
  }[];
}

const WidgetSubscription: React.FC<Props> = ({ lang, className, social }) => {
  const { t } = useTranslation('en', 'footer');

  return (
    <div className={cn('flex flex-col', className)}>
      <Heading variant="title" className="mb-2 uppercase font-normal">
        {t('widget-title-subscribe')}
      </Heading>

      <Text className="lg:-mt-1 max-w-[400px]">{t('text-subscribe')}</Text>
      <SubscriptionForm lang={lang} />
      {social && (
        <ul className="flex flex-wrap sm:justify-start mt-4">
          {social?.map((item) => (
            <li
              className="transition hover:opacity-80 cursor-pointer last:ltr:mr-0 md:ltr:mr-5 md:mx-0 ltr:mr-4 last:rtl:ml-0 rtl:ml-4 md:rtl:ml-5"
              key={`social-list--key${item.id}`}
            >
              <Link href={item.path ? item.path : '/#'} legacyBehavior>
                <Image
                  src={item.image}
                  alt={item.name}
                  height={item.height}
                  width={item.width}
                  className="transform scale-85 md:scale-100 w-[20px] h-[20px]"
                />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default WidgetSubscription;
