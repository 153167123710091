import CartIcon from '@components/icons/cart-icon';
import { useCart } from '@contexts/cart/use-cart';
import { useUI } from '@contexts/ui.context';
import { useTranslation } from 'src/app/i18n/client';
import cn from 'classnames';

type CartButtonProps = {
  lang: string;
  className?: string;
  iconClassName?: string;
  hideLabel?: boolean;
  // isShowing?: boolean;
};

const CartButton: React.FC<CartButtonProps> = ({
  lang,
  className,
  iconClassName = 'text-brand-dark text-opacity-40',
  hideLabel,
  // isShowing,
}) => {
  const { t } = useTranslation('en', 'common');
  const { openDrawer, setDrawerView } = useUI();
  const { cart } = useCart();

  function handleCartOpen() {
    setDrawerView('CART_SIDEBAR');
    // isShowing;
    return openDrawer();
  }

  return (
    <button
      className={cn(
        'flex items-center justify-center shrink-0 h-auto focus:outline-none transform',
        className,
      )}
      onClick={handleCartOpen}
      aria-label="cart-button"
    >
      <div className="relative flex items-center">
        <CartIcon className="h-5 w-5 text-gray-400" />
        {cart.items.length > 0 && (
          <span className="w-[15px] h-[15px] p-0.5 rounded-[20px] flex items-center justify-center bg-brand text-brand-light absolute -top-2 ltr:left-3.5 rtl:right-3.5 text-[8px] md:text-10px font-semibold">
            {cart.items.length}
          </span>
        )}
      </div>
      {!hideLabel && (
        <span className="text-sm font-normal lg:text-15px text-brand-dark ltr:ml-2 rtl:mr-2">
          {t('text-cart')}
        </span>
      )}
    </button>
  );
};

export default CartButton;
