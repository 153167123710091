import React from 'react'
import { zeroPad } from 'react-countdown';
import { CountdownDisplay } from './countdown-display';

type TCountdownProps = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
};

export const CountdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}: TCountdownProps) => {

    const counts = {
        days: zeroPad(days),
        hours: zeroPad(hours),
        minutes: zeroPad(minutes),
        seconds: zeroPad(seconds),
        completed: completed,        
    }

    if (completed)
    return null;
  
    return (
        CountdownDisplay(counts)
    );
}
