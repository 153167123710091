import { useMutation } from '@tanstack/react-query';
import { TAppoinmentForm } from '@services/mage/customer-types';
import {
  CreateAppointment,
  SubscribeNewsletter,
} from '@actions/customer/customer';

async function changeSubscribe(input: string) {
  return input;
}

export const useSubscribeMutation = () => {
  return useMutation({
    mutationFn: (input: string) => changeSubscribe(input),
    onSuccess: async (data) => {
      await SubscribeNewsletter(data);
    },
    onError: (data) => {
      console.log(data, 'Subscribe error response');
    },
  });
};
