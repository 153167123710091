import { TCustomer } from "@services/mage/customer-types";
import { useSession } from "next-auth/react";
import { ReactNode } from "react";
import { BiSearch } from "react-icons/bi";
import LogInfoButton from "@components/auth/loginfo-button";
import UserIcon from "@components/icons/user-icon";
import CurrencySwitcher from "@components/ui/currency-switcher";
import { ROUTES } from "@utils/routes";
import AuthMenu from "./auth-menu";

type TProps = {
    lang: string;
    children: ReactNode;    
    onLogin: () => void;
    onSearchOpen: () => void;    
}

const HeaderTools = ({lang, children, onLogin, onSearchOpen}: TProps) => {
  const { data: session } = useSession();
  const isAuthorized = (session) ? true : false;    
  const customer = session ? (session?.user as TCustomer) : ({} as TCustomer);

  return (
    <>
      <div className="hidden lg:flex xl:mx-2.5 mx-2">
        <div className="relative flex">          
          <div className="flex-1 mt-2">            
              <button
                type="button"
                onClick={() => onSearchOpen()}
              >
                <BiSearch className="h-5 w-5 text-gray-400" />
              </button>            
          </div>
        </div>
      </div>
      <div className="items-center hidden lg:flex shrink-0">
        {isAuthorized ? (
          <LogInfoButton lang={lang} customer={customer} />
        ) : (
          <AuthMenu
            isAuthorized={isAuthorized}
            href={`${ROUTES.ACCOUNT}`}
            btnProps={{
              children: <UserIcon className="h-5 w-5 text-gray-400" />,
              onClick: onLogin,
            }}
          ></AuthMenu>
        )}
      </div>
      <div className="hidden lg:flex ml-2.5">{children}</div>
      <div className="hidden lg:flex">
        <CurrencySwitcher lang={lang} />
      </div>
    </>
  );
};

export default HeaderTools;