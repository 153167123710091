import clsx from 'clsx';
import Image from 'next/image';

type TProps = {
  lang: string;
  className?: string;
  width?: number;
  height?: number;
};

const Logo = ({ lang, className, width, height }: TProps) => {
  const mainUrl = process.env.NEXT_PUBLIC_WEBSITE_URL + '/' + lang;
  return (
    <a
      href={`${mainUrl}`}
      className={clsx('inline-block focus:outline-none xs:w-[234px] sm:w-[234px] md:w-[248px] xl:w-[248px]', className)}
    >
      <Image
        src="https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/images/michaeltrio-logo.svg"
        alt="Michael Trio Singapore"
        width={244}
        height={32}        
        priority={true}        
      />
    </a>
  );
};

export default Logo;
