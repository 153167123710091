import React from 'react'

type CountdownProps = {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
    completed?: boolean;
    show?: boolean;
};

export const CountdownDisplay = (
    {
        days,
        hours,
        minutes,
        seconds,
        completed,
        show = true,
    }: CountdownProps
) => {

    if (!show) return null;

  return (
    <div className="flex gap-x-1 items-center font-semibold text-brand-light ltr:md:ml-2 rtl:md:mr-2 font-outfit md:py-1">
        <div className="flex md:my-1 items-end">
            <span className="flex items-center justify-center min-w-[24px] md:min-w-[28px] md:min-h-[20px] text-white font-medium text-15px md:text-[20px] rounded">
            {days}
            </span>
            <span className="font-light text-[10px] leading-normal">Days</span>
        </div>

        <div className="flex md:my-1 items-end">
            <span className="flex items-center justify-center min-w-[24px] md:min-w-[28px] md:min-h-[20px] text-white font-medium text-15px md:text-[20px] rounded">
            {hours}
            </span>
            <span className="font-light text-[10px] leading-normal">Hrs</span>
        </div>

        <div className="flex md:my-1 items-end">
            <span className="flex items-center justify-center min-w-[24px] md:min-w-[28px] md:min-h-[20px] text-white font-medium text-15px md:text-[20px] rounded">
            {minutes}
            </span>
            <span className="font-light text-[10px] leading-normal">Mins</span>
        </div>

        <div className="flex md:my-1 items-end">
            <span className="flex items-center justify-center min-w-[24px] md:min-w-[28px] md:min-h-[20px] text-white font-medium text-15px md:text-[20px] rounded">
            {seconds}
            </span>
            <span className="font-light text-[10px] leading-normal">Secs</span>
        </div>
    </div>
  )
}
