import React, { ReactNode } from 'react';
import HeaderMenuDesktop from './header-menu-desktop';
import HeaderMenuMobile from './header-menu-mobile';
import { ShippingCountry } from '@components/ui/shipping-country';

type THeaderMenuProps = {
  lang: string;
  children: ReactNode;
};

const HeaderMenu = ({ lang, children }: THeaderMenuProps) => {
  return (
    <>
      <HeaderMenuMobile lang={lang} />
      <HeaderMenuDesktop lang={lang} children={children} />
      <ShippingCountry lang={lang} />
    </>
  );
};

export default HeaderMenu;
